import React, { useEffect, useState } from 'react';

// Modules
import { BrowserRouter as Router } from 'react-router-dom';

// App
import Routes from './partials/routes';
import { getOnlineNode } from './core/getNode';

// UI components
import Container from 'react-bootstrap/Container';
import { CircularProgress } from '@mui/material';

// Style 
import './styles/main.scss';

function App() {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  
  const queryParams = new URLSearchParams(window.location.search);
  window.protocol = queryParams.get('protocol') ? queryParams.get('protocol') : 'https';
  window.subdomain = queryParams.get('subdomain');
  window.domain = `${window.protocol}://${window.subdomain}.companyapp.net`;
  window.id = queryParams.get('id');
  window.published = queryParams.get('published') === 'true' ? true : false;
  window.pdf = queryParams.get('pdf') === 'true' ? true : false;
  window.slideIds = queryParams.get('slide_ids') ? queryParams.get('slide_ids') : null;

  let path = `${window.domain}/api/bot_auth?username=screenshot&consumerId=${process.env.REACT_APP_CLIENT_ID}`;
  console.log(path)
  useEffect(() => {
    if (!window.subdomain || !window.id) {
      setLoading(false);
      setError(true);
    } else {
      getOnlineNode(path)
      .then(() => {
        // setLoading(false);
        getThemes();
      })
      .catch((_error) => {
        setLoading(false);
      });
    }
  }, []);

  const getThemes = () => {
    getOnlineNode(`${window.domain}/api/themes`)
      .then(response => {
        window.themes = response.data;
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }

  if (isLoading) {
    return (
      <div className='loading-container w-100 h-100 d-flex justify-content-center align-items-center'>
        <CircularProgress size={20} className={'m-0'} />
      </div>
    );
  }

  if (isError) {
    return (
      <div className='w-100' style={{height: '100vh'}}>
        <div className='d-flex justify-content-center align-items-center w-100 h-100'>
          <h6>Missing params</h6>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <div className={`page-container nav-collapsed`} data-page={'view-presentations'}>
        <Container fluid={true} data-page={'view-presentations'}>
          <Routes />
        </Container>
      </div>
    </Router>
  );
}

export default App